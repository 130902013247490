import { defineStore } from 'pinia';
import { reactive } from 'vue';
import axios from 'axios';

const {
  PS,
  localStorage: ls,
} = globalThis;

export const useCustomerStore = defineStore('customer', () => {
  const state = reactive({
    id: null,
    // psAppToken: '',
  });

  const psAppToken = async (customerId) => {
    const config = {
      baseURL: '/a/ps',
      method: 'post',
      url: '/get_token',
      headers: {
        'Content-Type': 'application/json',
      },
      data: JSON.stringify({
        customer_id: customerId,
      }),
    };
    try {
      const res = await axios(config);
      const token = await res.data.data.token;
      ls.setItem('psAppToken', token);
    } catch (err) {
      throw err;
    }
  };

  return { state, psAppToken };
});
